@tailwind base;
@tailwind components;
@tailwind utilities;

/*
    Add styles that will be appended to the stylesheet
    Custom styles must be added before the utilities so it can allow for other styels to extended them
*/
@layer components {
  .h1 {
    @apply text-2xl font-medium leading-7 text-gray-900 sm:leading-9 sm:truncate;
  }
  .h2 {
    @apply text-xl leading-7 text-gray-900 sm:leading-9 sm:truncate mr-2;
  }
  .brand-text {
    @apply text-green-600;
  }
  .brand-link {
    @apply text-blue-500 cursor-pointer hover:underline;
  }
  .brand-background {
  }
  .icon-transparent {
    @apply bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500;
  }
  .header-tag-green {
    @apply mx-0.5 px-3 py-2 text-xs inline-flex font-semibold rounded-full bg-green-100 text-green-800;
  }

  /*
  FORMS
  */
  select {
    @apply block w-full pr-4 pl-2 py-2 rounded-md border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm;
  }
  .multi-select {
    @apply text-sm;
  }
  .multi-select__control {
    @apply rounded-md border !important border-gray-300 !important;
  }
  textarea {
    @apply h-24;
  }
  .form-field {
    @apply appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm;
  }
  .form-error {
    @apply text-red-600;
  }
  form label {
    @apply text-sm leading-4;
  }
  /*
    BUTTONS
  */
  .button {
    @apply relative flex justify-center cursor-pointer py-2 px-3 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700;
  }
  .button-default {
    @apply relative flex justify-center cursor-pointer rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm;
  }
  .button-icon {
    @apply mr-1;
  }
  .button-transparent {
    @apply inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
  }
  .tag {
    font-size: 0.6rem !important;
  }
}

/*React Date Keeper */
.react-datepicker {
  border: 1px solid rgba(209, 213, 219, 1) !important;
  z-index: 10000 !important;
}
.react-datepicker__header {
  border-bottom: none !important;
  background-color: rgba(243, 244, 246, 1) !important;
}
.react-datepicker-popper {
  z-index: 50 !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: rgba(209, 213, 219, 1) !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: rgba(243, 244, 246, 1) !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0018a2 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #eeeeee !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f3f4f6 !important;
  color: #000 !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #d1d5db !important;
}

.react-datepicker__navigation {
  top: 4px;
}
